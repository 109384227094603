<template>
  <div>
    <page-title
      :heading="$t('generic.lang_menu_assignment')"
      :subheading="$t('generic.lang_menu_assignment')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <menu-assignment-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import MenuAssignmentComponent from "@/components/kiosk/menuAssignment/MenuAssignmentComponent";

export default {
  name: "MenuAssignmentView",
  components: {
    MenuAssignmentComponent,
    PageTitle,
  },

  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
