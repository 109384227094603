<template>
  <Datatable
    :api-endpoint="ENDPOINTS.DATATABLES.KIOSK.SEARCHITEMSMENU"
    :datatable-headers="datatableHeaders"
    :searchPayload="searchPayload"
    :datatable-attach-search="datatableAttachSearch"
    show-edit-buttons
    @editEntry="editEntry"
    enable-multi-sort
    :show-select-checkbox="false"
    hideOptionsMenu
  ></Datatable>
</template>

<script>
import { ENDPOINTS } from "@/config";
import Datatable from "../../datatable/Datatable";

export default {
  name: "MenuAssignmentComponent",
  components: {
    Datatable,
  },

  data() {
    return {
      ENDPOINTS,
      // id, number asignments, ean, name, itemgroup
      datatableHeaders: [
        {
          text: "ID",
          align: "left",
          value: "id",
          width: 80,
          hide: true,
        },
        {
          text: this.$t("erp.lang_ware_create_id"),
          value: "ean",
        },
        {
          text: this.$t("erp.lang_posItemName"),
          value: "name",
          searchable: true,
          searchCol: 6,
        },
        { text: this.$t("generic.lang_waregroup"), value: "itemGroup" },
        {
          text: this.$t("generic.lang_number_of_assignments"),
          value: "number_of_assignments",
          align: "center",
        },
      ],
      datatableAttachSearch: [
        {
          text: this.$t("generic.lang_waregroup"),
          value: "itemGroup",
          searchType: "multiselect",
          searchCol: 6,
        },
      ],
      searchPayload: {},
    };
  },

  async mounted() {
    await this.getSearchPayload();
  },

  methods: {
    async getSearchPayload() {
      let data = {};

      //ITEMGROUPS
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      data["itemGroup"] = itemgroups
        .map((itemgroup) => {
          return {
            id: itemgroup.id,
            name: itemgroup.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      this.searchPayload = data;
    },
    editEntry(entry) {
      this.$router.push({
        name: "kiosk.menu.assignment.edit.menu.assignment",
        params: { id: parseInt(entry.id) },
      });
    },
  },
};
</script>
